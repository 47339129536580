<template>
  <el-tooltip
    v-if="!!props.error"
    effect="dark"
    :content="`${props.error.name}: ${props.error.message}`"
    placement="bottom"
    popper-class="error-tooltip"
  >
    <el-icon color="#f56c6c"><WarnTriangleFilled /></el-icon>
  </el-tooltip>
</template>

<script setup lang="ts">
import { WarnTriangleFilled } from '@element-plus/icons-vue'
const props = defineProps({
  error: {
    type: Error as PropType<Error | null>,
    required: true,
    default: null,
  },
})
</script>

<style lang="postcss" scoped>
:global(.error-tooltip.el-popper) {
  background: #212126;
  border: 0.5px solid #f56c6c;
  border-radius: 8px;
  box-shadow: inset 0px 0px 6px #141414 !important;
}
:global(.error-tooltip.el-popper .el-popper__arrow::before) {
  background-color: #212126;
  border: 1px solid #f56c6c;
}
</style>
