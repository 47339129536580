<template>
  <div class="pools-header">
    <div class="pools-header__title">
      Sniper & HODL Pools
      <ErrorTooltip :error="props.error" />
    </div>
    <div class="pools-header__filters">
      <FilterSelect
        :disabled="disabled"
        select-label="Sort by:"
        :values="SORT_VALUES"
        :selected-value-id="props.selectedSortId"
        :on-change="(id: SortValue) => handleSortValueChange(id)"
      />
      <FilterSelect
        :disabled="disabled"
        :select-label="$t('labels.filter-by')"
        :values="STATUS_VALUES"
        :selected-value-id="props.selectedStatusId"
        :on-change="(id: PoolStatus) => handleStatusValueChange(id)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import FilterSelect from '~/components/basic/FilterSelect.vue'
import ErrorTooltip from '~/components/basic/ErrorTooltip.vue'
import type { PoolStatus, SortValue } from '@base/types/sniperPools'

const { t } = useCustomI18n()

const props = defineProps({
  selectedSortId: {
    type: String as PropType<SortValue>,
    required: true,
    default: 'vapr_high_low',
  },
  selectedStatusId: {
    type: String as PropType<PoolStatus>,
    required: true,
    default: 'active',
  },
  onChange: {
    type: Function,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: true,
    default: false,
  },
  error: {
    type: Error as PropType<Error | null>,
    required: true,
    default: null,
  },
})
const { getOriginalSymbol } = useTokens()

const SORT_VALUES: { id: SortValue; title: string }[] = [
  {
    id: 'vapr_high_low',
    title: t('labels.vapr-high-low'),
  },
  {
    id: 'vapr_low_high',
    title: t('labels.vapr-low-high'),
  },
  {
    id: 'latest_earliest',
    title: t('labels.latest-earliest'),
  },
  {
    id: 'earliest_latest',
    title: t('labels.earliest-latest'),
  },
  {
    id: 'tvl_high_low',
    title: t('labels.tvl-high-low'),
  },
  {
    id: 'tvl_low_high',
    title: t('labels.tvl-low-high'),
  },
]

const STATUS_VALUES: { id: PoolStatus; title: string }[] = [
  {
    id: 'all',
    title: t('labels.all'),
  },
  {
    id: 'call',
    title: getOriginalSymbol('sellTokenSNIPER'),
  },
  {
    id: 'put',
    title: 'uSNIPER',
  },
  {
    id: 'hodl',
    title: 'HODL',
  },
]

const handleSortValueChange = (id: SortValue) => {
  props.onChange({
    selectedSortId: id,
    selectedStatusId: props.selectedStatusId,
  })
}

const handleStatusValueChange = (_id: PoolStatus) => {
  props.onChange({
    selectedSortId: props.selectedSortId,
    selectedStatusId: _id,
  })
}
</script>

<style lang="postcss" scoped>
.pools-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
}
.pools-header__title {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
}
.pools-header__filters {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

@media screen and (max-width: 1024px) {
  .pools-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 24px;
  }
  .pools-header__title {
    margin-bottom: 16px;
    font-size: 24px;
  }
}

@media screen and (max-width: 640px) {
  .pools-header {
    margin-bottom: 20px;
  }
  .pools-header__title {
    font-size: 20px;
  }
  .pools-header__filters {
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
  }
}
</style>
