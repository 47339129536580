<template>
  <div class="select-container">
    <template v-if="props.selectLabel && props.selectLabel?.length > 0">
      <p class="select-label">{{ props.selectLabel }}</p>
    </template>
    <el-select
      v-model="value"
      :placeholder="$t('labels.select')"
      popper-class="select-popper"
      class="select"
      :disabled="disabled"
      :title="values.find((v) => v.id === selectedValueId)?.title"
    >
      <el-option
        v-for="item in props.values"
        :key="item.id"
        :label="item.title"
        :value="item.id"
      />
    </el-select>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  values: {
    type: Array<{ id: string; title: string }>,
    default: [],
    required: true,
  },
  selectedValueId: {
    type: String,
    default: undefined,
    required: true,
  },
  onChange: {
    type: Function,
    default: () => {},
    required: true,
  },
  selectLabel: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})
const value = ref(props.selectedValueId)

watch([value], () => {
  props.onChange(value.value)
})
</script>
<style scoped lang="postcss">
.select.el-select {
  min-width: 200px;
}
.select-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.select-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  color: rgba(255, 255, 255, 0.9);
}
:global(.select.el-select .el-input__wrapper) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 8px;
  font-size: 12px;
  /* font-weight: 600; */
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  background-color: #26262b;
  border: 1px solid #777777;
  border-radius: 8px;
  box-shadow: none;
  transition: 200ms all ease-in-out;
}
:global(.select.el-select .el-input__wrapper.is-focus) {
  box-shadow: none !important;
}
:global(.select.el-select .el-input__inner) {
  /* font-weight: 600; */
}
:global(.select.el-select .el-input__wrapper:hover) {
  border-color: rgba(255, 255, 255, 0.75);
  box-shadow: none;
}
:global(.select.el-select .el-input.is-focus .el-input__wrapper) {
  border-color: rgba(255, 255, 255, 0.75);
  box-shadow: none !important;
}
:global(.select-popper .el-select-dropdown__item:hover) {
  color: var(--yellow);
  background-color: #26262b;
}
:global(
    .select-popper .el-select-dropdown__list > .el-select-dropdown__item.hover
  ) {
  color: var(--yellow);
  background-color: #26262b;
}
:global(
    html.root
      .select-popper.el-select__popper.el-popper
      .el-select-dropdown__list
      > .el-select-dropdown__item.selected,

  ) {
  font-weight: 400;
  color: var(--yellow);
  background-color: #26262b;
}

:global(
    html.root
      .select-popper.el-select__popper.el-popper
      .el-select-dropdown__list
      > .el-select-dropdown__item.selected::after,

  ) {
  background-color: var(--yellow);
}

@media only screen and (max-width: 640px) {
  .select.el-select {
    width: 100%;
    min-width: 100%;
  }
}
</style>
